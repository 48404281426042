export default class MediaLayout implements IMediaLayout
{
  MediaLayoutID: string;
  GridSize: number;
  Column1Width: number;
  Column2Width: number;
  Row1Height: number;
  Row2Height: number;
  CellPadding: number;
  Pane3OnTop: boolean;
  TransitionMode: string;
  TransitionDuration: number;
  BrowserZoom: number;
  MediaDeviceId03: string;
  MediaDeviceId01: string;
  MediaDeviceId02: string;
  MediaDeviceId04: string;
  MediaDeviceId01Name: string;
  MediaDeviceId02Name: string;
  MediaDeviceId03Name: string;
  MediaDeviceId04Name: string;
}
