import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileDropModule } from 'ngx-file-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
//import { PdfViewerModule, PdfViewerComponent } from 'ng2-pdf-viewer';
import { PdfJsViewerModule, PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

import
{
  MatTabsModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTableModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatButtonModule,
  MatRadioModule,
  MatSnackBarModule,
  MatDialogModule,
  MatListModule
} from '@angular/material'

import { AppComponent } from './app.component';

import { EncodeUriPipe } from './pipes/encode-uri.pipe';
import { RoleFunctionDirective } from './rolefunction.directive';
import { LocalizeDirective } from './localize.directive';
import { PresenterDirective } from './presenter.directive';
import { LocaleService } from './locale.service';
import { DialogComponent } from './dialog/dialog.component';
import { AuthInterceptor } from './authinterceptor';
import { WebPresenterComponent } from './webpresenter/webpresenter.component';
import { LayoutComponent } from './layout/layout.component';
import { UriBuilderService } from './_services/uribuilder.service';
import { GetMediaService } from './_services/getmedia.service';

@NgModule({
  declarations: [
    AppComponent,
    RoleFunctionDirective,
    LocalizeDirective,
    PresenterDirective,
    DialogComponent,
    LayoutComponent,
    WebPresenterComponent,
    EncodeUriPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    CommonModule,
    RouterModule.forRoot([
      //{ path: '', component: WebPresenterComponent, pathMatch: 'full' },
      //{ path: 'webpresenter/:deviceID', component: WebPresenterComponent }
      { path: '', component: LayoutComponent, pathMatch: 'full' },
      { path: 'webpresenter/:deviceID', component: LayoutComponent }
    ]),
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    FileDropModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    PdfJsViewerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) =>
      {

        var language = localeService.getLanguage();
        console.log('evision5 webplayer', language);
        console.log('locale ID', language);
        return language;
      },
      deps: [LocaleService]
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    PdfJsViewerComponent,
    DialogComponent
  ]
})
export class AppModule { }
