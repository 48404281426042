import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetMediaService, GetMediaWorker } from '../_services/getmedia.service';
import MediaLayout from '../../models/MediaLayout';
import VirtualDevice from '../../models/VirtualDevice';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})

export class LayoutComponent
{
  @Output()
  OnReady: EventEmitter<object> = new EventEmitter<object>();

  @Input('Settings')
  set Settings(_value: IMediaSettingsWebPresenterCollection) { this.settings = _value; }
  get Settings(): IMediaSettingsWebPresenterCollection { return this.settings; }

  @Input('Fullscreen')
  set Fullscreen(_value: boolean) { this.fullscreen = _value; }
  get Fullscreen(): boolean { return this.fullscreen; }

  @Input('Muted')
  set Muted(_value: boolean) { this.muted = _value; }
  get Muted(): boolean { return this.muted; }

  @Input('Layer')
  set Layer(_value: number) { this.layer = _value; }
  get Layer(): number { return this.layer; }

  @Input('Index')
  set Index(_value: number) { this.index = _value; }
  get Index(): number { return this.index; }

  @Input('Frame')
  set Frame(_value: number) { this.frame = _value; }
  get Frame(): number { return this.frame; }

  @Input('DeviceID')
  set DeviceID(_value: string) { this.deviceID = _value; }
  get DeviceID(): string { return this.deviceID; }

  private frame: number = 0;
  private index: number = 0;
  private layer: number = 0;
  private device: IMediaDeviceForWebPresenter = null;
  private deviceID: string = null;
  private fullscreen: boolean = false;
  private muted: boolean = true;
  private settings: IMediaSettingsWebPresenterCollection = null;

  private getDataTimeOut: number = 1;
  private getDataTimeOutID: number = null;
  private getActivityTimeOutID = null;

  private getMediaWorker: GetMediaWorker;

  // STATES: fadein, visible, hidden
  private toggleContext: { Current: IMediaLayoutFrame, Next: IMediaLayoutFrame };
  private mediaFrames: IMediaLayoutFrame[] = [];
  private active: number = null;

  constructor(private route: ActivatedRoute,
    private getMediaService: GetMediaService)
  {
    this.getMediaWorker = this.getMediaService.Get();

    this.mediaFrames.push({ index: 0, isActive: false, state: 'hidden', devices: [], mediaLayout: null, mediaActivity: null, subLayoutPaneStates: [false, false, false, false] });
    this.mediaFrames.push({ index: 1, isActive: false, state: 'hidden', devices: [], mediaLayout: null, mediaActivity: null, subLayoutPaneStates: [false, false, false, false] });
  };

  private ngOnInit()
  {
    if (this.layer === 0)
    {
      this.deviceID = this.route.snapshot.paramMap.get('deviceID');

      // Get parameters
      this.route.queryParams.subscribe(params =>
      {
        this.fullscreen = params['fullscreen'] === 'true';
      });
    }

    this.getData();

    // Enforce refresh of media first for this device.
    this.getMediaWorker.RefreshMedia().subscribe(() =>
    {
      this.getActivityRecurring();
    });
  };

  private ngOnDestroy()
  {
    if (this.getActivityTimeOutID !== null && this.getActivityTimeOutID !== undefined)
    {
      clearTimeout(this.getActivityTimeOutID);
    }

    if (this.getDataTimeOutID !== null && this.getDataTimeOutID !== undefined)
    {
      clearTimeout(this.getDataTimeOutID);
    }
  };

  /**
   * Perform this in intervalls.
   */
  private getActivityRecurring()
  {
    // Get activity
    this.getActivity().subscribe(() =>
    {
      // Retrigger after timeout
      this.getActivityTimeOutID = window.setTimeout(() =>
      {
        clearTimeout(this.getActivityTimeOutID);
        this.getActivityRecurring();
      }, 1000);
    });
  };

  /**
   * Gets current mediaActivity for this device.
   */
  private getActivity(): Observable<boolean>
  {
    var o = new Observable<boolean>(_observer =>
    {
      // Settings required.
      if (this.settings === null)
      {
        window.setTimeout(() =>
        {
          _observer.next(false);
          _observer.complete();
        }, 1)
        return;
      }

      this.getMediaWorker.SetFullscreen(this.fullscreen);
      this.getMediaWorker.SetDevice(this.deviceID);
      this.getMediaWorker.GetMedia().subscribe((_mediaActivity: IMediaActivityContent) =>
      {
        this.processActivity(_mediaActivity);

        _observer.next(true);
        _observer.complete();
      });
    });
    return o;
  };

  /**
   * Checks if mediaActivity or layout has changed and builds layout.
   * @param _mediaActivity
   */
  private processActivity(_mediaActivity: IMediaActivityContent)
  {
    let activity = _mediaActivity;
    let nextLayout: IMediaLayout = null;
    let devices = [];

    // Check layout
    if (activity.mediaLayout !== null && activity.mediaLayout !== undefined)
    {
      nextLayout = activity.mediaLayout;

      if (nextLayout.GridSize >= 1)
      {
        let d = new VirtualDevice();
        d.deviceID = nextLayout.MediaDeviceId01;
        devices.push(d);
      }

      if (nextLayout.GridSize >= 2)
      {
        let d = new VirtualDevice();
        d.deviceID = nextLayout.MediaDeviceId02;
        devices.push(d);
      }

      if (nextLayout.GridSize >= 3)
      {
        let d = new VirtualDevice();
        d.deviceID = nextLayout.MediaDeviceId03;
        devices.push(d);
      }

      if (nextLayout.GridSize >= 4)
      {
        let d = new VirtualDevice();
        d.deviceID = nextLayout.MediaDeviceId04;
        devices.push(d);
      }
    }
    else
    {
      nextLayout = new MediaLayout();
      nextLayout.GridSize = 1;

      let d = new VirtualDevice();
      d.deviceID = this.deviceID;
      devices.push(d);
    }

    // Check if layout has changed and toggle transition.
    let activeIndex = this.active === null ? 0 : this.active;
    let newActiveIndex = activeIndex === 0 ? 1 : 0;
    let currentLayout = this.mediaFrames[activeIndex].mediaLayout;

    // Apply changes to corresponding panel
    if (this.layoutHasChanged(nextLayout, currentLayout))
    {
      this.mediaFrames[newActiveIndex].subLayoutPaneStates = [false, false, false, false];
      this.mediaFrames[newActiveIndex].devices = devices;
      this.mediaFrames[newActiveIndex].mediaLayout = nextLayout;
      this.mediaFrames[newActiveIndex].mediaActivity = activity;

      if (this.toggleContext !== null && this.toggleContext !== undefined)
      {
        return;
      }

      this.toggleContext = {
        Current: this.mediaFrames[activeIndex],
        Next: this.mediaFrames[newActiveIndex]
      };
      this.toggle();
    }
    else
    {
      this.mediaFrames[activeIndex].mediaActivity = activity;
    }

    let p = { Layer: this.layer, Index: this.index, Frame: this.frame };
    this.OnReady.emit(p);
  };

  /**
   * Returns identifier for identification of layout-panel.
   * @param _index
   * @param _item
   */
  private trackBy(_index, _item)
  {
    return _item.deviceID;
  };

  /**
   * Checks if layout has changed. Compares two layouts.
   * @param _newMediaLayout
   */
  private layoutHasChanged(_newMediaLayout: IMediaLayout, _current: IMediaLayout): boolean
  {
    //let current = this.currentMediaLayout;
    let hasChanged =
      (_newMediaLayout === null && _current !== null) ||
      (_newMediaLayout !== null && _current === null) ||
      _newMediaLayout.GridSize !== _current.GridSize;

    if (hasChanged === false)
    {
      hasChanged =
        (_current.GridSize >= 1 && _current.MediaDeviceId01 !== _newMediaLayout.MediaDeviceId01) ||
        (_current.GridSize >= 2 && _current.MediaDeviceId02 !== _newMediaLayout.MediaDeviceId02) ||
        (_current.GridSize >= 3 && _current.MediaDeviceId03 !== _newMediaLayout.MediaDeviceId03) ||
        (_current.GridSize >= 4 && _current.MediaDeviceId04 !== _newMediaLayout.MediaDeviceId04)
    }

    return hasChanged;
  };

  /**
   * Toggles panels for transition. Checks if toggle can be directly performed
   * or it is required to wait for new layout to be built.
   */
  private toggle()
  {
    let newActiveItem = this.toggleContext.Next;
    if (newActiveItem.mediaLayout.GridSize > 1)
    {
      // Wait for new layout to be ready
    }
    else
    {
      this.switchPanels();
    }
  };

  /**
   * Switchs panels by setting states.
   */
  private switchPanels()
  {
    let currentItem = this.toggleContext.Current;
    let newActiveItem = this.toggleContext.Next;

    // Check if sublayout is ready.
    if (newActiveItem.mediaLayout.GridSize === 2 &&
      (newActiveItem.subLayoutPaneStates[0] !== true ||
        newActiveItem.subLayoutPaneStates[1] !== true))
    {
      return;
    }
    else if (newActiveItem.mediaLayout.GridSize === 3 &&
      (newActiveItem.subLayoutPaneStates[0] !== true ||
        newActiveItem.subLayoutPaneStates[1] !== true ||
        newActiveItem.subLayoutPaneStates[2] !== true))
    {
      return;
    }
    else if (newActiveItem.mediaLayout.GridSize === 4 &&
      (newActiveItem.subLayoutPaneStates[0] !== true ||
        newActiveItem.subLayoutPaneStates[1] !== true ||
        newActiveItem.subLayoutPaneStates[2] !== true ||
        newActiveItem.subLayoutPaneStates[3] !== true))
    {
      return;
    }

    if (newActiveItem.state === 'visible' || newActiveItem.state === 'fadein')
    {
      return;
    }

    // TODO: Quickfix: Little timeout to enable preloading of media.
    //let timeout = 750;
    //setTimeout(() =>
    //{
    // Set 'fadein'
    newActiveItem.isActive = true;
    newActiveItem.state = 'fadein';

    // Set timeout to change to 'visible'
    setTimeout(() =>
    {
      // Change active-index
      this.active = newActiveItem.index;// newActiveIndex;

      // Change to 'visible'
      newActiveItem.state = 'visible';
      currentItem.state = 'hidden';

      // This timeout is required to detach the change to 'visible' from 'hidden'.
      setTimeout(() =>
      {
        // Reset state of previous visible item.
        currentItem.isActive = false;

        // Clear previous layout
        currentItem.devices = [];
        currentItem.mediaLayout = null;
        currentItem.mediaActivity = null;

        // Done
        //this.isToggling = false;
        this.toggleContext = null;
      });

    }, this.settings.crossFadingDurationMsValue);

    //}, timeout);
  };

  /**
   * Child-layout is ready. Continue animation.
   */
  public OnReadyChildHandler(_event)
  {
    this.mediaFrames[_event.Frame].subLayoutPaneStates[_event.Index] = true;

    this.switchPanels();
  };

  /**
   * Gets basic-data in intervalls.
   */
  private getData()
  {
    this.getDataTimeOutID = window.setTimeout(() =>
    {
      // Get data
      this.getSettings();
      this.getDevice();

      // Check the current interval
      var ms = 5000;
      if (this.settings !== null && this.settings.refreshSettingsMsIntervalValue >= 0)
      {
        ms = this.settings.refreshSettingsMsIntervalValue;
      }

      if (ms !== this.getDataTimeOut)
      {
        // Interval has changed by settings. Therefore cancel current
        // interval and restart via calling getData().
        window.clearTimeout(this.getDataTimeOutID);
        this.getDataTimeOutID = null;
        this.getDataTimeOut = ms;
      }

      this.getData();

    }, this.getDataTimeOut);
  };

  /**
   *  Gets general settings.
   */
  private getSettings()
  {
    this.getMediaWorker.GetSettings().subscribe((_settings: IMediaSettingsWebPresenterCollection) =>
    {
      this.settings = _settings;
      this.muted = this.getMediaWorker.IsMuted();
    });
  };

  /**
   * Gets the device.
   */
  private getDevice()
  {
    this.getMediaWorker.GetDevice().subscribe((_device: IMediaDeviceForWebPresenter) =>
    {
      this.device = _device;
      this.muted = this.getMediaWorker.IsMuted();
    });
  };
}
