import { Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UriBuilderService } from '../_services/uribuilder.service';

@Component({
  selector: 'app-webpresenter',
  templateUrl: './webpresenter.component.html',
  styleUrls: ['./webpresenter.component.css']
})

export class WebPresenterComponent
{
  @Input('Settings')
  set Settings(_value: IMediaSettingsWebPresenterCollection) { this.settings = _value; }
  get Settings(): IMediaSettingsWebPresenterCollection { return this.settings; }

  @Input('Fullscreen')
  set Fullscreen(_value: boolean) { this.fullscreen = _value; }
  get Fullscreen(): boolean { return this.fullscreen; }

  @Input('Muted')
  set Muted(_value: boolean) { this.muted = _value; }
  get Muted(): boolean { return this.muted; }

  @Input('DeviceID')
  set DeviceID(_value: string) { this.deviceID = _value; }
  get DeviceID(): string { return this.deviceID; }

  @Input('MediaActivity')
  set MediaActivity(_value: IMediaActivityContent) { this.mediaActivity = _value; this.detectChange(_value); }
  get MediaActivity(): IMediaActivityContent { return this.mediaActivity; };

  private settings: IMediaSettingsWebPresenterCollection = null;
  private mediaActivity: IMediaActivityContent = null;
  private deviceID: string = null;
  private fullscreen: boolean = false;
  private muted: boolean = true;
  private isFirst: boolean = true;

  // STATES: fadein, visible, hidden
  private mediaFrames: IContentFrame[] = [];
  private active: number = null;
  private urls: SafeResourceUrl[] = [];
  private isToggling: boolean = false;

  constructor(private uriBuilderService: UriBuilderService)
  {
    this.mediaFrames.push({ url: null, isActive: false, state: 'hidden', secondsPerPage: null });
    this.mediaFrames.push({ url: null, isActive: false, state: 'hidden', secondsPerPage: null });

    this.urls[0] = null;
    this.urls[1] = null;
  };

  private ngOnInit()
  {
  };

  /**
   * Checks if activity has changed and triggers transition if required.
   * @param _content
   */
  private detectChange(_content: IMediaActivityContent)
  {
    if (_content === null)
    {
      return;
    }

    let activeIndex = this.active === null ? 0 : this.active;
    let newActiveIndex = activeIndex === 0 ? 1 : 0;

    _content.value = this.modifyUrl(_content.value);
    if (this.mediaFrames[activeIndex].url !== _content.value ||
      this.mediaFrames[activeIndex].secondsPerPage !== _content.secondsPerPage)
    {
      this.mediaFrames[newActiveIndex].url = _content.value;
      this.mediaFrames[newActiveIndex].secondsPerPage = _content.secondsPerPage;
      this.toggle();
    }
  };

  /**
   * Toggles panels for transition.
   */
  private toggle()
  {
    if (this.isToggling === true)
    {
      return;
    }
    this.isToggling = true;

    let activeIndex = this.active === null ? 0 : this.active;
    let newActiveIndex = activeIndex === 0 ? 1 : 0;

    let currentItem = this.mediaFrames[activeIndex];
    let newActiveItem = this.mediaFrames[newActiveIndex];

    // Set url for item
    var tmp = newActiveItem.url;
    this.urls[newActiveIndex] = tmp;

    if (newActiveItem.state === 'visible' || newActiveItem.state === 'fadein')
    {
      this.isToggling = false;
      return;
    }

    // Check if this is initial appearnance. Then no fade.
    // Fade is performed by layout.
    if (this.isFirst)
    {
      this.isFirst = false;

      this.active = newActiveIndex;

      newActiveItem.isActive = true;
      newActiveItem.state = 'visible';

      currentItem.isActive = false;
      currentItem.state = 'hidden';

      this.isToggling = false;

      return;
    }

    // TODO: Quickfix: Little timeout to enable preloading of media.
    setTimeout(() =>
    {
      // Set 'fadein'
      newActiveItem.isActive = true;
      newActiveItem.state = 'fadein';

      // Set timeout to change to 'visible'
      setTimeout(() =>
      {
        // Change active-index
        this.active = newActiveIndex;

        // Change to 'visible'
        newActiveItem.state = 'visible';

        // Reset state of previous visible item.
        currentItem.isActive = false;
        currentItem.state = 'hidden';

        this.isToggling = false;

      }, this.settings.crossFadingDurationMsValue);

    }, 750);
  };

  /**
   * Modifies url to show youtube/twitch/vimeo if required.
   * @param _url
   */
  private modifyUrl(_url: string): string
  {
    var url = _url;

    if (this.settings !== null)
    {
      var isYouTube = false;
      var isVimeo = false;
      var isTwitch = false;
      for (var i = 0; i < this.settings.youTubeHostsValue.length; i++)
      {
        if (url.indexOf(this.settings.youTubeHostsValue[i]) >= 0)
        {
          isYouTube = true;
          break;
        }

        if (url.indexOf(this.settings.vimeoHostsValue[i]) >= 0)
        {
          isVimeo = true;
          break;
        }

        if (url.indexOf(this.settings.twitchHostsValue[i]) >= 0)
        {
          isTwitch = true;
          break;
        }
      }

      if (isYouTube)
      {
        var uriBuilder = this.uriBuilderService.Get();
        url = uriBuilder
          .Parse(url)
          .ClearSegments()
          .AddSegment('embed')
          .AddSegment(uriBuilder.QueryStrings['v'])
          .AddQueryString('playlist', uriBuilder.QueryStrings['v'])
          .AddQueryString('autoplay', '1')
          .AddQueryString('controls', '0')
          .AddQueryString('loop', '1')
          .AddQueryString('showinfo', '0')
          .AddQueryString('mute', (this.muted ? '1' : '0'))
          .RemoveQueryString('v')
          .RemoveQueryString('ts')
          .RemoveQueryString('fullscreen')
          .Build();
      }

      if (isVimeo)
      {
        var uriBuilder = this.uriBuilderService.Get().Parse(url);
        var vimeoId = uriBuilder.Segments[0].Value;

        url = uriBuilder
          .ClearSegments()
          .UseHost('player.vimeo.com')
          .AddSegment('video')
          .AddSegment(vimeoId)
          .AddQueryString('autoplay', '1')
          .AddQueryString('loop', '1')
          .AddQueryString('autopause', '0')
          .AddQueryString('muted', (this.muted ? 'true' : 'false'))
          .RemoveQueryString('v')
          .RemoveQueryString('ts')
          .RemoveQueryString('fullscreen')
          .Build();
      }

      if (isTwitch)
      {
        var uriBuilder = this.uriBuilderService.Get().Parse(url);

        var isTwitchVideo = uriBuilder.Segments.length > 0 && uriBuilder.Segments[0].Value === 'videos';
        var twitchVideoID = uriBuilder.Segments.length > 1
          ? uriBuilder.Segments[1].Value
          : '';

        url = uriBuilder
          .ClearSegments()
          .UseHost('player.twitch.tv')
          .AddQueryString(isTwitchVideo ? 'video' : 'channel', twitchVideoID)
          .AddQueryString('muted', (this.muted ? '1' : '0'))
          .AddQueryString('autoplay', 'true')
          .AddQueryString('parent', encodeURIComponent('www.evision5.cloud'))
          .RemoveQueryString('ts')
          .RemoveQueryString('fullscreen')
          .Build();
      }
    }

    return url;
  };
}
