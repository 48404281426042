import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { HttpErrorService } from './httperror.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
  constructor(private router: Router,
    private httpErrorService:HttpErrorService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    return next
      .handle(request)
      //.pipe(catchError((err, caught: Observable<HttpEvent<any>>) =>
      .pipe(catchError((err: HttpErrorResponse) =>
      {
        if (err instanceof HttpErrorResponse && err.status == 401)
        {
          this.httpErrorService.http401.next(true);
          //this.router.navigate(['login'], { queryParams: { returnUrl: request.url } });
          return of(err as any);
        }
        throw err;
      }));
  }
}
